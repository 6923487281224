<template>
	<section id="section-2" class="section-content">

		<h2 id="d4">Main Features of WhatsApp Plus APK</h2>

		<div class="intro-li-row">
			<ul class="list-icons">
				<li v-for="(data,i) in doc.s2.ul" :key="i"><i class="fa"></i>
					<strong v-html="data.head"></strong>
					<div class="list-detail" v-html="data.content"></div>
					<div class="list-img" v-if="data.img">
						<lazypic :img="data.img" :alt="data.alt"></lazypic>
					</div>
				</li>
			</ul>
		</div>

		<h2 id="d5">What's New in WhatsApp Plus V18.10?</h2>
		<ul class="common-list">
			<li v-for="(data,i) in doc.s2.new" :key="i">
				{{ data }}
			</li>
		</ul>

		<h2 id="d6">How to Download and Install WhatsApp Plus</h2>

		<p>
			To install it, you'll need to manually download WhatsApp Plus APK and install it on your Android device. Here's how:		
			</p>

		<ul class="common-list number-list">
			<li>
				<strong>Enable "Unknown Sources": </strong>Go to your phone's Settings > Security and enable the option for Unknown Sources.
			</li>
			<li>
				<strong>Download the APK: </strong>Visit a trusted site to download the WhatsApp Plus APK.
			</li>
			<li>
				<strong>Install the APK: </strong>Open the downloaded file and follow the on-screen instructions to complete the installation.
			</li>
		</ul>
		<p>
			After completing these steps, WhatsApp Plus will be installed and ready for use on your device!
		</p>

		<div class="advertisment-item">
			<lazypic :img="doc.s2.adimg_2" alt="Download WhatsApp Plus APK"></lazypic>
		</div>

		<h2 id="d7">Conclusion</h2>
		<p>
			With WhatsApp Plus's advanced features and enhanced privacy options, it's no wonder users are making the switch for a more personalized messaging experience.
		</p>

	</section>
</template>

<script>
import lazypic from "@/components/lazypic.vue";

export default {
	name: "s2",
	components: {
		lazypic,
	},
	props: {
		doc: Object,
	},
	methods: {
		gotochat() {
			window.location.href = "https://www.whatsplus.site/";
		},
		gotoorg() {
			window.location.href = "https://www.whatspro.org/";
		},
	},
};
</script>
