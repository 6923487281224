<template>
  <div id="download" class="big-download-btn" @click="showModal()">
    <img src="@/assets/gb/downlaod.png" class="dowanlod-img" alt="dowanlod" />
    <div class="dowanlod-name">
      Download {{ txt }}
    </div>
  </div>
</template>
<script>
export default {
  name: "dowanlod",
  props: {
    from: String,
    filename: String,
    isOffical: {
      type: Number,
      default: 0,
    },
    name: String,
    txt: String,
  },
  data () {
    return {
      domainMap: {
        home: {
          domain: "gbwhatsapp.download",
          appName: "GB_gbwhatsapp.download",
        },
        gb: {
          domain: "gbwhatsapp.download",
          appName: "GB_gbwhatsapp.download",
        },
        ob: {
          domain: "gbwhatsapp.download",
          appName: "OB_gbwhatsapp.download",
        },
        yo: {
          domain: "gbwhatsapp.download",
          appName: "YO_gbwhatsapp.download",
        },
        plus: {
          domain: "gbwhatsapp.download",
          appName: "PLUS_gbwhatsapp.download",
        },
      },
      baseURL: "https://gbproapks.com",

    };
  },
  mounted () {
    console.log("------------->", this.name);
    console.log(`${this.baseURL}${this.concatenatedString()}`);
  },
  methods: {
    concatenatedString () {
      let add = "";
      switch (this.name) {
        case "ob":
          add = "/obwhatsapp-apk-download";
          break;
        case "plus":
          add = "/whatsapp-plus-apk-download";
          break;
        case "yo":
          add = "/yowhatsapp-apk-download";
          break;
        default:
          add = "";
          break;
      }

      return add;
    },
    download () {
      if (this.name) {
        console.log(this.domainMap);
        window.location.href = `/download?apps=${this.name}&from=${this.from
          }&filename=${this.filename}&isOffical=${this.isOffical
          }&domain=${this.domainMap[this.name].domain}&appName=${this.domainMap[this.name].appName
          }`;
      } else {
        window.location.href = `/download?apps=gb&from=${this.from}&filename=${this.filename}&isOffical=${this.isOffical}&domain=gbwhatsapp.download&appName=GB_gbwhatsapp.download`;
      }

      this.$logEvent(`click_${this.name}home_download`);
    },
    showModal () {
      if (!this.$root.isMobileDevice) {
        this.download();
      }
      if (this.$root.shouldRedirect) {
        this.download();
      } else {
        this.$root.isModalVisible = true;
        this.$logEvent(`show_modal_ad`);
      }
    },
  },
};
</script>
