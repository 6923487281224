<template>
	<section id="section-apk-detail" class="section-content">
		<h2 class="intro-title intro-title-2">
			Download WhatsApp Plus APK
		</h2>

		<table id="apkdetail" cellspacing="0">
			<tbody>
				<tr>
					<td>
						<div>App Name</div>
					</td>
					<td>
						<span>WhatsApp Plus APK</span> 
					</td>
				</tr>
				<tr v-for="(data, i) in apkdetailList" :key="i">
					<td>
						<div v-html="data.name"></div>
					</td>
					<td>
						<div v-html="data.value"></div>
					</td>
				</tr>
				<!-- <tr>
					<td>
						<div>RATING</div>
					</td>
					<td>
						<div v-if="$root.initData && $root.initData.settings">
							<span>{{ $root.initData.settings.score }}</span> ( {{ $root.initData.settings.totalCount }} ratings )
						</div>
						<div v-else>
							<span>3.65</span> ( 46258 ratings )
						</div>
					</td>
				</tr> -->
			</tbody>
		</table>
	</section>
</template>

<script>
export default {
	name: 'apkdetail',
	data() {
		return {
			apkdetailList: [],
		}
	},
	props: {
		doc: Object,
	},
	mounted() {
		this.apkdetailList = [
			{
				name: "App size",
				value: "71 MB",
			},
			{
				name: "Version",
				value: "V18.10",
				itemprop: "version",
			},
			{
				name: "Developer",
				value: "Alex Mods",
			},
			{ 
				name: "Price",
				value: "Free",
			},
			{
				name: "Last Update",
				value: "2 days ago",
				itemprop: "update",
			},
		]
	},
	
}
</script>
